import React from "react";
import "../styles/ContentCard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsPreview } from "store/postSlice";
import useUserInfo from "hooks/useUserInfo";
import { setAuth, setShowUpgrade } from "store/mainSlice";
import { AUTH_STEP, INTERCOM_EVENTS } from "Utils";
import { useIntercom } from "react-use-intercom";
import useInterview from "hooks/useInterview";
import CopyContent from "./CopyContentButton";

const ContentCard = ({ post, icon }) => {
  const { posts, articles, threads, tweets, answers, reels } = useSelector(
    (state) => state.post
  );
  const { interviewId } = useInterview();
  const fullyAvailable = [
    ...posts,
    ...articles,
    ...threads,
    ...tweets,
    ...answers,
    ...reels,
  ].every((post) => !post.isOpenedFirst);
  const { isSubscribed, isAuth, isSignedUp, profile } = useUserInfo();
  const intercom = useIntercom();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToPost = () => {
    if (isAuth) {
      if (isSubscribed || fullyAvailable || post.isOpenedFirst) {
        navigate(`/posts/${interviewId}/${post._id}`);
        dispatch(setIsPreview(false));
      } else {
        dispatch(setShowUpgrade(true));
        return;
      }
    } else if (isSignedUp) {
      dispatch(setAuth(AUTH_STEP.sign_in));
      return;
    } else {
      dispatch(setAuth(AUTH_STEP.sign_up));
      if (profile.interviews === 4) {
        intercom.trackEvent(INTERCOM_EVENTS.reachedLimit, {
          subscription_link: `${window.location.origin}/subscribe`,
        });
      }
      return;
    }
  };

  const { title, description: content, hashtags: tags } = post;
  let displayTags = [],
    lastTagsLength = 0;
  if (tags.length < 4) displayTags = tags;
  else {
    displayTags = tags.slice(0, 3);
    lastTagsLength = tags.length - displayTags.length;
  }

  return (
    <div
      className="rounded-[20px] p-3 bg-white text-gray-900 w-full h-[240px] gap-4 flex flex-col"
      style={{
        boxShadow: "0px 6.157px 18.472px 0px rgba(149, 157, 165, 0.20)",
      }}
    >
      <div className="flex justify-start gap-1 items-center text-blue-grey font-inter text-[12px] leading-[150%]">
        <span>Post on:</span>
        {icon && <img src={icon} alt="icon" className="size-6" />}
      </div>
      <div className="flex flex-col w-full gap-2 flex-1">
        <div className="flex-1 relative overflow-y-auto scroll-dark scroll-hidden">
          <div className="absolute w-full space-y-2">
            <div className="font-figtree text-black-bg text-[16px] font-[500] leading-[130%] text-left w-full">
              {title || content}
            </div>
            <div className="text-[14px] text-pretty text-left text-blue-grey font-figtree leading-[111%] ">
              {content}
            </div>
          </div>
        </div>
        <div className="flex gap-1 w-full flex-wrap">
          {displayTags.map((tag, index) => (
            <div
              key={index}
              className="rounded-[10px] bg-[#F9F1ED] p-1 text-gray-700 text-center font-figtree leading-[88%] text-[12px]"
            >
              {tag.charAt(0) === "#" ? tag : "#" + tag}
            </div>
          ))}
          {lastTagsLength > 0 && (
            <div className="rounded-[10px] bg-[#F9F1ED] p-1 text-gray-700 text-center font-figtree leading-[88%] text-[12px]">
              {"+" + lastTagsLength}
            </div>
          )}
        </div>
        <div className="w-full flex items-center justify-between">
          <button
            className="py-1 px-2.5 rounded-[40px] border border-primary-main text-primary-main font-figtree font-[700] leading-[22px] tracking-[0.46px] text-[13px] w-fit"
            onClick={goToPost}
          >
            Review & Publish
          </button>
          <CopyContent postId={post._id} />
        </div>
      </div>
    </div>
  );
};

export default ContentCard;
