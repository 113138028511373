import SweetAlert from "sweetalert2";
import moment from "moment-timezone";
// import { marked } from "marked";
// import striptags from "striptags";
import removeMarkdown from "markdown-to-text";

export const meetingMinutes = 30;

export const RECURRING_VALUES = {
  WEEKLY: "weekly",
  NOT_REPEAT: "not-repeat",
  MONTHLY: "monthly"
};

export const INTERVIEW_STATUS = {
  CREATED: 0,
  OPENED: 1,
  INTERVIEWING: 2,
  DONE: 3,
  FAILED: 4,
};

export const INTERVIEW_STATE = {
  INTERVIEWER_SPEAKING: 0,
  RECORDING: 1,
  PAUSED_RECORDING: 2,
  REVIEWING: 3,
  SENDING: 4,
  BEFORE_START: 5,
};

export const POST_STATUS = {
  NOT_STARTED: 0,
  PENDING: 1,
  FAILED: 2,
  SUCCESS: 3,
};

export const PAGE_TYPES = {
  MEETING: "meeting",
  REGISTRATION: "register",
  POSTS: "posts",
  SCHEDULE: "schedule",
  MANAGE: "manage",
  PERSONALIZATION: "personalization",
};

export const IMAGE_MANAGER_TABS = {
  NO_TAB: "0_no_tab",
  UPLOADER: "1_uploader",
  GENERATOR: "2_ai",
  TEMPLATE: "3_template",
};

export const SPEED_VALUES = ["1.00", "1.25", "1.50", "0.50", "0.75"];

export const QUEUE_URL = "https://q-ueue.ai";

export const CIRCLE_TOPICS_SOURCES = {
  linkedin: "linkedin",
  twitter: "twitter",
  website: "website",
};

export const DRAFT_TYPES = {
  podcast: "podcast",
  posts: "posts",
  articles: "articles",
  answers: "answers",
  threads: "threads",
  tweets: "tweets",
  reels: "reels",
  rawData: "raw-data",
};

export const INTERCOM_EVENTS = {
  submitted_details: "jessica_submit_details",
  selected_time: "jessica_select_time",
  created_interview: "jessica_create_interview",
  startInterview: "jessica_start_interview",
  finishInterview: "jessica_finish_interview",
  submitAnswer: "jessica_submit_answer", // prefix needed
  submitFirstAnswer: "jessica_submit_first_answer",
  visitPostPage: "jessica_visit_post_page",
  clickOnPostIt: "jessica_click_on_post_it",
  changedTopic: "jessica_change_topic_of_interview",
  reachedLimit: "jessica_has_reached_limit",
  succeededSubscription: "jessica_succeeded_subscription",
  notSelectedTopic: "jessica_not_selected_topic",
  notScheduled: "jessica_not_scheduled",
  startSignup: "jessica_start_signup",
  notSetupWeeklyCall: "jessica_not_setup_weekly_call",
  setupWeeklyCall: "jessica_setup_weekly_call",
  succeededPublish: "jessica_succeeded_publish",
  invalidLinkedin: "jessica_invalid_linkedin",
  notCompletedSubscription: "jessica_not_completed_subscription",
};

export const AUTH_STEP = {
  no_auth: 0,
  sign_up: 1,
  sign_in: 2,
  forgotPassword: 3,
  submittedEmail: 4,
  resetPassword: 5,
};

export const PERSONALIZATION_STEP = {
  selectingSource: 0,
  selectingPosts: 1,
  completed: 2,
};

export const PERSONALIZATION_SOURCE = {
  myPosts: 0,
  OthersPosts: 1,
};

export const md2plain = (text) => {
  // return striptags(marked(text));
  return removeMarkdown(text);
};

export const get5MinsDate = () => {
  const date = new Date();
  const timestamp = date.getTime();
  const fiveMinsTimestamp = 5 * 60 * 1000;
  let newTimestamp =
    Math.ceil(timestamp / fiveMinsTimestamp) * fiveMinsTimestamp;
  return new Date(newTimestamp);
};

export const get15MinsDate = () => {
  const date = new Date();
  const timestamp = date.getTime();
  const fiveMinsTimestamp = meetingMinutes * 60 * 1000;
  let newTimestamp =
    Math.ceil(timestamp / fiveMinsTimestamp) * fiveMinsTimestamp;
  return new Date(newTimestamp);
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const showSuccessMessage = (content) => {
  return SweetAlert.fire({
    icon: "success",
    title: "Success",
    html: content,
    showConfirmButton: true,
    confirmButtonText: "OK",
  });
};

export const showErrorMessage = (content) => {
  return SweetAlert.fire({
    icon: "error",
    title: "Error",
    html: content,
    showConfirmButton: true,
    confirmButtonText: "OK",
  });
};

export const showInfoMessage = (content) => {
  SweetAlert.fire({
    icon: "info",
    title: "Info",
    html: content,
    showConfirmButton: true,
    confirmButtonText: "OK",
  });
};

export const showQuestionMessage = (content) => {
  return SweetAlert.fire({
    icon: "question",
    title: "Confirm",
    html: content,
    showConfirmButton: true,
    confirmButtonText: "OK",
  });
};

export function getDateTime(
  inputDate,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  const now = inputDate;
  const options = {
    timeZone: timeZone,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDateTime = now.toLocaleString("en-US", options);

  // Extract individual components
  const [date, time] = formattedDateTime.split(", ");
  const [month, day, year] = date.split("/");
  const [hour, minute] = time.split(":");

  return {
    day: parseInt(day),
    month: parseInt(month) - 1,
    year: parseInt(year),
    hour:
      parseInt(hour) +
      (time.includes("PM") && parseInt(hour) < 12 ? 12 : 0) -
      (time.includes("AM") && parseInt(hour) === 12 ? 12 : 0),
    minute: parseInt(minute),
  };
}

export function getCurrentDateTime(
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  const now = new Date();
  const options = {
    timeZone: timeZone,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDateTime = now.toLocaleString("en-US", options);

  // Extract individual components
  const [date, time] = formattedDateTime.split(", ");
  const [month, day, year] = date.split("/");
  const [hour, minute] = time.split(":");

  return {
    day: parseInt(day),
    month: parseInt(month) - 1,
    year: parseInt(year),
    hour:
      parseInt(hour) +
      (time.includes("PM") && parseInt(hour) < 12 ? 12 : 0) -
      (time.includes("AM") && parseInt(hour) === 12 ? 12 : 0),
    minute: parseInt(minute),
  };
}

export function capitalize(word) {
  if (!word || word.length < 1) return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const splitPost = (post) => {
  if (!post) {
    return { title: "", content: "", tags: [] };
  }
  // Split the post by new lines
  const lines = post.split("\n");
  if (!lines || lines.length === 0) {
    return { title: "", content: "", tags: [] };
  }

  // The title is the first line
  let title = lines.shift();

  while (lines.length > 0 && !/[A-Za-z0-9]+/.test(title)) {
    title = lines.shift();
  }

  // The rest of the array is joined back into a string to form the content
  let content = lines.join("\n");

  // Find tags - words starting with '#'
  let tags = [];
  const words = post.match(/\S+/g) || [];

  words.forEach((word) => {
    if (word.startsWith("#") && word.replace(/#/g, "").length > 0) {
      tags.push(word);
    }
  });

  tags = tags.filter((tag, index, self) => self.indexOf(tag) === index);

  // The result includes title, content, and tags
  return { title, content, tags };
};

export function checkFutureTime({ min, hour, date, month, year, timezone }) {
  const inputDate = moment.tz([year, month, date, hour, min, 0, 0], timezone);
  const currentDate = moment.tz(timezone);
  return inputDate.isAfter(currentDate);
}

export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const cutLinkedin = (linkedin) =>
  linkedin
    .replace(/^\/+|\/+$/g, "")
    .replace(/\s/g, "")
    .split("/")
    .pop();

export function obfuscateEmail(email) {
  if (!isValidEmail(email)) return "";
  const [local, domain] = email.split("@");
  const obfuscatedLocal =
    local.charAt(0) + "*".repeat(local.length - 2) + local.slice(-1);
  const domainParts = domain.split(".");
  const obfuscatedDomain =
    domainParts[0].charAt(0) +
    "*".repeat(domainParts[0].length - 2) +
    domainParts[0].slice(-1) +
    "." +
    domainParts[1];

  return `${obfuscatedLocal}@${obfuscatedDomain}`;
}

export function toUtcDate(dateObj) {
  const { year, month, day, hour, minute, timezone } = dateObj;
  const options = {
    timeZone: timezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  // Format the date string according to the timezone
  const formattedDateParts = new Intl.DateTimeFormat(
    "en-US",
    options
  ).formatToParts(new Date(Date.UTC(year, month - 1, day, hour, minute)));

  const dateStr = `${
    formattedDateParts.find((part) => part.type === "year").value
  }-${formattedDateParts.find((part) => part.type === "month").value}-${
    formattedDateParts.find((part) => part.type === "day").value
  }T${formattedDateParts.find((part) => part.type === "hour").value}:${
    formattedDateParts.find((part) => part.type === "minute").value
  }:${formattedDateParts.find((part) => part.type === "second").value}`;

  // Return the timestamp in milliseconds since the epoch
  return new Date(dateStr + "Z").getTime();
}

export const getInterviewDate = (dateObj) => {
  const { year, month, day, hour, minute, timezone } = dateObj;

  // Create a date string
  const dateString = `${year}-${String(month + 1).padStart(2, "0")}-${String(
    day
  ).padStart(2, "0")}T${String(hour).padStart(2, "0")}:${String(
    minute
  ).padStart(2, "0")}:00`;

  // Parse with specified time zone
  const date = moment.tz(dateString, timezone);

  // Convert to local time zone
  const localDate = date.clone().tz(moment.tz.guess());

  // Get today's and yesterday's start of day for comparison
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");

  // Determine which message to display
  let displayMessage;
  if (localDate.isSame(today, "d")) {
    displayMessage = "Today";
  } else if (localDate.isSame(yesterday, "d")) {
    displayMessage = "Yesterday";
  } else {
    displayMessage = localDate.format("MMM Do");
  }

  return displayMessage;
};

export function countWords(text) {
  // Use a regular expression to match words
  const words = text.match(/\b\w+\b/g);

  // If no words are found, return 0
  if (!words) {
    return 0;
  }

  // Return the number of words
  return words.length;
}

export const downloadFile = (url, fileName) => {
  if (!url.trim()) {
    console.log("Error before downloading : url is required");
    return;
  }
  if (!fileName?.trim()) {
    fileName = url
      .split("/")
      .filter((word) => word.trim())
      .pop();
    if (!fileName) {
      console.log("Error before downloading : fileName is required", fileName);
      return;
    }
  }
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error("Error downloading image:", error));
};

export const getWeekNumber = (year, month, day) => {
  const date = new Date(year, month, day, 0, 0, 0, 0);
  return Math.floor((date.getDate() - 1) / 7) + 1;
};

export function getNextDay(year, month, day, i, recurring) {
  let current = new Date(year, month, day, 0, 0, 0, 0); // month is 0-indexed in JavaScript Date
  let weekNumber, targetWeekNumber, expectedMonth, recordDate;
  
  if (recurring === RECURRING_VALUES.WEEKLY) {
    // Calculate the new date by adding 'i' weeks (7 days per week)
    current.setDate(current.getDate() + i * 7);
  } else if (recurring === RECURRING_VALUES.MONTHLY) {
    // Set to the first day of the next month

    targetWeekNumber = getWeekNumber(year, month, day);
    weekNumber = getWeekNumber(year, month + i, 1);
    current = new Date(year, month, 1, 0, 0, 0, 0); // month is 0-indexed in JavaScript Date
    current.setMonth(current.getMonth() + i);
    expectedMonth = current.getMonth();
    console.log(expectedMonth);
    // Find the same day of the week in the new month
    const targetWeekday = new Date(year, month, day, 0, 0, 0, 0).getDay();

    while (
      current.getDay() !== targetWeekday ||
      weekNumber !== targetWeekNumber
    ) {
      if (current.getMonth() !== expectedMonth) {
        console.log(recordDate);
        return recordDate;
      }
      if (current.getDay() === targetWeekday) {
        recordDate = {
          year: current.getFullYear(),
          month: current.getMonth(),
          day: current.getDate(),
        };
      }
      current.setDate(current.getDate() + 1);
      weekNumber = getWeekNumber(
        current.getFullYear(),
        current.getMonth(),
        current.getDate()
      );
    }

    // Calculate the week number
  }

  // Extract the year, month, and day from the new date
  const newYear = current.getFullYear();
  const newMonth = current.getMonth(); // month is 0-indexed, so add 1
  const newDay = current.getDate();

  console.log(current);

  return { year: newYear, month: newMonth, day: newDay };
}
