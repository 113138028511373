import { BASE_SERVER_URL, SERVER_URL } from "api";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setProfile } from "store/mainSlice";

const useManageAvatar = () => {
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleUpload = async (image, url) => {
    if (!image) return;

    setIsUploading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axios.put(url, image, {
        headers: {
          "Content-Type": image.type,
        },
        body: image,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // setUploadProgress(percentCompleted);
          console.log(`Upload Progress: ${percentCompleted}%`);
        },
      });
      console.log(response.data);
      await axios.post(`${BASE_SERVER_URL}/api/v2/users/profile/public`, {
        image: url?.split("?")?.at(0) || "",
      });
      dispatch(setProfile({ picture: url?.split("?")?.at(0) || "" }));
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsUploading(false);
    }
  };

  const handleInterviewUpload = async (
    image,
    interviewId,
    email = "",
    onlyThis = true
  ) => {
    if (!image) return;

    setIsUploading(true);
    setError(null);
    setSuccess(false);

    try {
      const fileName = image.name;
      const fileExtension = fileName.split(".").pop();
      const res1 = await axios.get(
        `${SERVER_URL}/interview-sessions/${interviewId}/signed-url?fileExtension=${fileExtension}`
      );
      if (!res1.data?.split("?")?.at(0)) {
        throw new Error("Empty url response");
      }
      await axios.put(res1.data, image, {
        headers: {
          "Content-Type": image.type,
        },
        body: image,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // setUploadProgress(percentCompleted);
          console.log(`Upload Progress: ${percentCompleted}%`);
        },
      });
      await axios.put(
        onlyThis
          ? `${SERVER_URL}/interview-sessions/${interviewId}/avatar`
          : `${SERVER_URL}/interview-sessions/avatar`,
        {
          picture: res1.data?.split("?")?.at(0) || "",
          email,
        }
      );
      setSuccess(true);
      setIsUploading(false);
      return res1.data?.split("?")?.at(0);
    } catch (err) {
      setError(err.message);
    }
    setIsUploading(false);
  };

  return {
    uploadImage: handleUpload,
    uploadInterviewImage: handleInterviewUpload,
    isUploading,
    error,
    success,
  };
};

export default useManageAvatar;
