import useUserInfo from "hooks/useUserInfo";
import React from "react";
import Jessica from "assets/images/sidebar/icon-jessica.png";
import useInterview from "hooks/useInterview";
import { Rating } from "@mui/material";
import { useSelector } from "react-redux";
import { StarOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Check from "assets/images/post/check.svg";
import UserAvatar from "./UserAvatar";

const DashboardHeader = ({ showPersonalization = true }) => {
  const navigate = useNavigate();
  const {
    profile: { personalized },
  } = useUserInfo();
  const { topic, score, interviewId, guestMode } = useInterview();
  const { posts } = useSelector((state) => state.post);
  // get the date of the post
  function formatDate(date) {
    // Months array to convert month index to month name
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the day of the month
    const day = date.getDate();

    // Get the month index and year
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Function to define the correct ordinal indicator ('st', 'nd', 'rd', or 'th')
    function getOrdinalIndicator(day) {
      if (day > 3 && day < 21) return "th"; // teens always end in 'th'
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    // Build the string
    return `${months[monthIndex]} ${day}${getOrdinalIndicator(day)}, ${year}`;
  }
  const formattedDate =
    posts.length > 0 ? formatDate(new Date(posts[0].createdAt)) : "";
  const personalize = async () => {
    navigate(`/personalize?interviewId=${interviewId}`);
  };
  return (
    <div className="w-full flex flex-col xl:flex-row justify-start xl:items-center gap-3 px-7 pt-0 pb-6">
      <div className="flex flex-1 items-center gap-3">
        <div className="flex flex-row-reverse items-end gap-0 flex-shrink-0">
          {
            <div className="w-[50px] h-[50px] flex-shrink-0 rounded-full bg-pink-bright overflow-hidden -ml-3.5">
              <img
                src={guestMode?.assistant?.picture || Jessica}
                alt="jessica"
                className="w-full h-full"
              />
            </div>
          }
          <UserAvatar
            alt={"user"}
            className="rounded-full flex-shrink-0 w-[60px] h-[60px] z-[1] bg-off-white-light"
          />
        </div>
        <div className="flex flex-col gap-3 items-start flex-1 pt-2">
          <div className="text-black-bg font-figtree text-[20px] sm:text-[24px] md:text-[26px] lg:text-[32px] leading-[100%] max-w-full">
            {`${topic} 🚀`}
          </div>
          <div className="text-action-active font-figtree leading-[150%]">
            <span>{`Created: ${formattedDate} | `}</span>
            {score >= 0 && (
              <span className="whitespace-nowrap inline-flex items-center gap-1">
                Interview Score :{" "}
                <Rating
                  value={Number.parseFloat(score)}
                  readOnly
                  precision={0.1}
                  emptyIcon={<StarOutline sx={{ fill: "#EDC515" }} />}
                />
              </span>
            )}
          </div>
        </div>
      </div>
      {showPersonalization && (
        <div
          className="flex-shrink-0 link interact flex px-3 py-1.5 lg:px-3.5 lg:py-2 justify-start lg:justify-center items-center gap-2 rounded-[40px] border border-cta-blue text-nowrap font-inter text-[14px] font-[600] leading-[20px]"
          onClick={personalize}
        >
          {personalized && (
            <img src={Check} alt="link" className="flex-shrink-0 size-5" />
          )}
          <span style={{ color: " #0e24d6" }}>
            {personalized ? "Tone of Voice" : "Set up your tone of voice"}
          </span>
          {personalized || (
            <span className="hidden sm:inline">to Improve your posts</span>
          )}
        </div>
      )}
    </div>
  );
};

export default DashboardHeader;
