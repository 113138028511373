import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  initPreparation,
  PREPARATION_STEP,
  setDate,
  setGuestMode,
  setHour,
  setMin,
  setMonth,
  setPreparationStep,
  setRecurring,
  setScheduledId,
  setTimezone,
  setTopics,
  setYear,
} from "store/preparationSlice";
import ArrowBackIcon from "assets/images/preparation/ArrowBack.svg";
import TopicSelector from "./components/TopicSelector";
import DateSelector from "./components/DateSelector";
import SetAudiencePopup from "./components/AudiencePopup/SetAudiencePopup";
import useUserInfo from "hooks/useUserInfo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { initInterviewData, setInterviewPicture } from "store/interviewSlice";
import { setProfile, setRegisterEmail, setSchedules } from "store/mainSlice";
import {
  checkFutureTime,
  get15MinsDate,
  getCurrentDateTime,
  getDateTime,
  INTERCOM_EVENTS,
  meetingMinutes,
  RECURRING_VALUES,
  showErrorMessage,
} from "Utils";
import axios from "axios";
import { SERVER_URL } from "api";
import useCookie from "hooks/useCookie";
import ScheduledPopup from "./components/ScheduledPopup";
import AddLinkedinPopup from "components/Popups/AddLinkedinPopup/AddLinkedinPopup";
import { useIntercom } from "react-use-intercom";
// import { Player } from "@lottiefiles/react-lottie-player";
// import Fire from "./animations/Fire Animated Icon.json";
import "./Preparation.css";
import SetGuestModePopup from "./components/SetGuestModePopup";

const Preparation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intercom = useIntercom();
  const location = useLocation();
  const { scheduleId: sId } = useParams();
  const {
    step,
    isSettingUpAudience,
    isSettingUpGuestMode,
    recurring,
    year,
    month,
    date,
    hour,
    min,
    timezone,
    topics,
    scheduledId,
    guestMode,
  } = useSelector((state) => state.preparation);
  const {
    profile,
    isChecking,
    isSignedUp,
    isAuth,
    schedules,
    isCheckingSchedules,
  } = useUserInfo();
  const {
    officialFirstName,
    officialLastName,
    firstName,
    headline,
    linkedin,
    email,
    company,
    picture,
  } = profile;
  const { setCookieEmail } = useCookie();
  const [waitAdd, setWaitAdd] = useState(false);
  const [waitStart, setWaitStart] = useState(false);
  const [pending, setPending] = useState(true);
  const [open, setOpen] = useState(false);
  const wait = waitAdd || waitStart;
  const scheduled = useRef(false);
  useEffect(() => {
    (async () => {
      try {
        console.log(sId);
        if (sId) {
          setPending(true);
          const { data: schedule } = await axios.get(
            `${SERVER_URL}/schedules/${sId}`
          );
          console.log(schedule);
          dispatch(
            setProfile({
              firstName: schedule.firstName,
              email: schedule.email,
              company: schedule.company,
              officialFirstName: schedule.officialFirstName,
              officialLastName: schedule.officialLastName,
              headline: schedule.headline,
              linkedin: schedule.linkedin,
            })
          );
          dispatch(setInterviewPicture(schedule.picture));
          dispatch(setTopics(schedule.interviews || [schedule]));
          dispatch(setPreparationStep(PREPARATION_STEP.TOPIC_SELECTION));
          dispatch(setYear(schedule.schedule.year));
          dispatch(setMonth(schedule.schedule.month));
          dispatch(setDate(schedule.schedule.day));
          dispatch(setHour(schedule.schedule.hour));
          dispatch(setMonth(schedule.schedule.month));
          dispatch(setTimezone(schedule.schedule.timezone));
          dispatch(setRecurring(schedule.recurring));
          dispatch(setGuestMode(schedule.guestMode));
          setPending(false);
        } else {
          setPending(false);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.status === 404) {
          navigate("/not-found");
          return;
        }
        showErrorMessage("Failed to load schedule");
      }
    })();
  }, [dispatch, isCheckingSchedules, navigate, sId, schedules]);
  useEffect(() => {
    dispatch(initInterviewData());
    if (!sId) dispatch(initPreparation());
  }, [dispatch, sId, location]);
  useEffect(() => {
    if (
      !isChecking &&
      !(sId && pending) &&
      (!profile.email.trim() || (isSignedUp && !isAuth))
    ) {
      navigate("/");
    }
  }, [
    dispatch,
    profile.email,
    isAuth,
    isChecking,
    isSignedUp,
    navigate,
    sId,
    pending,
  ]);
  const getDateTimeString = useCallback(() => {
    let startDate = new Date(year, month, date, hour, min);
    let endDate = new Date(year, month, date, hour, min + meetingMinutes);

    if (hour < 0) {
      const today = getCurrentDateTime(timezone);
      startDate = new Date(
        today.year,
        today.month,
        today.day,
        today.hour,
        today.minute
      );
      endDate = new Date(
        today.year,
        today.month,
        today.day,
        today.hour,
        today.minute + meetingMinutes
      );
    }

    const formatDate = (date) => {
      const options = { weekday: "long", month: "long", day: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);

      const suffix = getNumberSuffix(date.getDate());

      return `${formattedDate}${suffix}, ${year}`;
    };

    const getNumberSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return "th";
      }

      const lastDigit = day % 10;

      switch (lastDigit) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const formattedStartDate = `${startDate.getHours()}:${String(
      startDate.getMinutes()
    ).padStart(2, "0")}`;
    const formattedEndDate = `${endDate.getHours()}:${String(
      endDate.getMinutes()
    ).padStart(2, "0")}`;

    const result = `${formattedStartDate} - ${formattedEndDate} ${formatDate(
      startDate
    )}`;
    return result;
  }, [date, hour, min, month, timezone, year]);
  const createSchedule = async (
    isAdd = recurring === RECURRING_VALUES.WEEKLY,
    newLinkedin = linkedin,
    newLinkedinProfile = null
  ) => {
    let new_min = min,
      new_hour = hour,
      new_date = date,
      new_month = month,
      new_year = year;
    if (wait || isChecking) return;
    if (topics.length === 0) {
      showErrorMessage("Please select a question or input custom topic.");
      return;
    }

    if (isAdd && !checkFutureTime({ min, hour, date, month, year, timezone })) {
      const today = getDateTime(get15MinsDate(), timezone);
      new_date = today.day;
      new_hour = today.hour;
      new_min = today.minute;
      new_month = today.month;
      new_year = today.year;
      dispatch(setHour(new_hour));
      dispatch(setMin(new_min));
      dispatch(setDate(new_date));
      dispatch(setMonth(new_month));
      dispatch(setYear(new_year));
    }
    if (!newLinkedin) {
      setOpen(true);
      return;
    }
    if (isAdd) setWaitAdd(true);
    else setWaitStart(true);
    try {
      const response = await axios.post(
        `${SERVER_URL}/interview-sessions/create-v2`,
        {
          expertDetails: {
            firstName: firstName || newLinkedinProfile.firstName,
            email,
            linkedinProfile: newLinkedinProfile || {
              officialFirstName,
              officialLastName,
              company,
              picture,
              headline,
            },
            linkedin: newLinkedin || linkedin,
          },
          interviewDetails: {
            questions: topics.map((t) => t.topic),
            scheduleTime: {
              year: new_year,
              month: new_month,
              day: new_date,
              hour: isAdd ? new_hour : -1,
              minute: isAdd ? new_min : -1,
              timezone,
            },
            recurring: recurring,
          },
          guestModeDetails: guestMode,
          scheduleId: sId,
        },
        { timeout: 100 * 1000 }
      );
      intercom.trackEvent(INTERCOM_EVENTS.created_interview, {
        topic: topics?.join(","),
        recurring: true,
      });
      setOpen(false);
      dispatch(setRegisterEmail(email));
      setCookieEmail(email);
      // if (redirectUrl) {
      //   if (!recurring) {
      //     intercom.trackEvent(INTERCOM_EVENTS.notSetupWeeklyCall, {
      //       link: `${window.location.origin}/schedule`,
      //       firstName,
      //     });
      //   }
      // }
      if (recurring === RECURRING_VALUES.WEEKLY) {
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        intercom.trackEvent(INTERCOM_EVENTS.setupWeeklyCall, {
          firstName,
          date: getDateTimeString().split(" ").slice(4).join(" "),
          time: getDateTimeString().split(" ").shift(),
          day_of_week:
            daysOfWeek[new Date(year, month, date, hour, min).getDay()],
          timezone,
        });
      }
      scheduled.current = true;
      let newSchedules = [...schedules];
      const scheduleIndex = schedules.findIndex((s) => s._id === sId);
      if (sId && scheduleIndex > -1) {
        const newSchedule = { ...schedules[scheduleIndex], ...response.data.schedule };
        newSchedules.splice(scheduleIndex, 1, newSchedule);
      } else {
        newSchedules = [...newSchedules, response.data.schedule];
      }
      dispatch(setSchedules(newSchedules));
      console.log(sId);
      
      if (sId) {
        navigate("/my-calls");
        return;
      }
      if (isAdd) {
        dispatch(setScheduledId(response.data.interviewId));
      } else navigate(`/meeting/${response.data.interviewId}`);
    } catch (error) {
      console.log(error);
      showErrorMessage(
        error.response?.data?.message || "Internal server error"
      );
    }
    setWaitAdd(false);
    setWaitStart(false);
  };

  const sendNotSelectedTopic = useCallback(async () => {
    const response = await axios.get(
      `${SERVER_URL}/services/topics/${headline}`
    );
    const responseTopics = response.data;
    intercom.trackEvent(INTERCOM_EVENTS.notSelectedTopic, {
      topics: responseTopics.map((obj) => obj.text).slice(0, 3),
      date: getDateTimeString().split(" ").slice(4).join(" "),
      time: getDateTimeString().split(" ").shift(),
      firstName,
    });
  }, [firstName, getDateTimeString, headline, intercom]);

  const sendNotScheduled = useCallback(async () => {
    intercom.trackEvent(INTERCOM_EVENTS.notScheduled, {
      topic: topics?.join(", "),
      date: getDateTimeString().split(" ").slice(4).join(" "),
      time: getDateTimeString().split(" ").shift(),
      firstName,
    });
  }, [firstName, getDateTimeString, intercom, topics]);

  useEffect(() => {
    return () => {
      if (!scheduled.current)
        if (topics.length > 0) sendNotScheduled();
        else sendNotSelectedTopic();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (pending)
    return (
      <div className="h-full flex items-center justify-center">
        <h2 style={{ color: "black", textAlign: "center", fontSize: "36px" }}>
          Loading...
        </h2>
      </div>
    );
  return (
    <div className="preparation w-full flex-1 p-5 justify-center items-center flex flex-col gap-8">
      {step === PREPARATION_STEP.DATE_SELECTION && (
        <div className="w-full flex flex-start">
          <div
            className="flex flex-grow-0 gap-2 items-center text-primary-main text-[14px] font-[700] leading-[171.429%] tracking-[0.4px] cursor-pointer"
            onClick={() => {
              dispatch(setPreparationStep(PREPARATION_STEP.TOPIC_SELECTION));
              // dispatch(setTopics([]));
            }}
          >
            <img src={ArrowBackIcon} alt="back" />
            <span>Back to Topics Selection</span>
          </div>
        </div>
      )}
      {step === PREPARATION_STEP.TOPIC_SELECTION && !!sId && (
        <div className="w-full flex flex-start">
          <div
            className="flex flex-grow-0 gap-2 items-center text-primary-main text-[14px] font-[700] leading-[171.429%] tracking-[0.4px] cursor-pointer"
            onClick={() => {
              navigate("/my-calls");
              // dispatch(setTopics([]));
            }}
          >
            <img src={ArrowBackIcon} alt="back" />
            <span>Back to My Calls Page</span>
          </div>
        </div>
      )}
      {step === PREPARATION_STEP.TOPIC_SELECTION && (
        <TopicSelector
          wait={waitStart}
          createSchedule={createSchedule}
          scheduleId={sId}
        />
      )}
      {step === PREPARATION_STEP.DATE_SELECTION && (
        <DateSelector
          waitAdd={waitAdd}
          waitStart={waitStart}
          createSchedule={createSchedule}
          scheduleId={sId}
        />
      )}
      {isSettingUpAudience && <SetAudiencePopup parentWait={false} />}
      {isSettingUpGuestMode && <SetGuestModePopup />}
      {scheduledId && <ScheduledPopup />}
      {open && (
        <AddLinkedinPopup
          setOpen={setOpen}
          parentWait={wait}
          isWeekly={recurring === RECURRING_VALUES.WEEKLY}
          callback={createSchedule}
        />
      )}
    </div>
  );
};

export default Preparation;
