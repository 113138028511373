import { CircularProgress } from "@mui/material";
import { showErrorMessage, showInfoMessage } from "Utils";
import { SERVER_URL } from "api";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setPost } from "store/postSlice";
// import Unlike from "assets/images/post/unlike.svg";
import Jessica from "assets/images/post-edit/jessica.png";
import arrowUp from "assets/images/post-edit/arrow-up.svg";
import "../styles/EditForm.css";
import Vector from "assets/images/post-edit/Vector 1755.svg";
import UserAvatar from "components/UserAvatar";

const EditForm = () => {
  const dispatch = useDispatch();
  const { interviewId: iId, postId: pId } = useParams();
  const { post } = useSelector((state) => state.post);
  const [opinion, setOpinion] = useState("");
  const [wait, setWait] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (wait || opinion.trim().length === 0) return;
    if (post?.contents?.length > 5) {
      showInfoMessage(
        "You have reached the maximum limit of 5 changes for this post."
      );
      return;
    }
    setWait(true);
    try {
      const response = await axios.post(`${SERVER_URL}/posts/${iId}/${pId}`, {
        opinion,
      });
      dispatch(setPost(response.data));
      setOpinion("");
    } catch (error) {
      console.log(error);
      showErrorMessage(
        error.response?.data?.message || "Internal server error"
      );
    }
    setWait(false);
  };
  return (
    <>
      {wait && opinion && (
        <div className="w-full flex items-center gap-1">
          <UserAvatar alt={"user"} className="size-9" />
          <div className="flex-1 flex items-center gap-0">
            <img
              src={Vector}
              alt="vector"
              className="no-select w-[11px] h-auto -mr-[1px]"
            />
            <div className="flex-1 rounded-[4px] bg-[#EFF8FF] py-1 px-2 font-inter text-[16px] leading-[150%] text-black-bg whitespace-pre">
              {opinion}
            </div>
          </div>
        </div>
      )}
      <div className="editor-actions rounded-b-[20px]">
        <img
          src={Jessica}
          alt="jessica"
          className="w-[40px] h-auto flex-shrink-0"
        />
        <form onSubmit={handleSubmit} className="relative">
          <textarea
            type="text"
            name="opinion"
            id="opinion"
            placeholder="Ask Jessica to adjust your content"
            value={wait ? "" : opinion}
            onChange={(e) => setOpinion(e.target.value)}
            readOnly={wait}
            rows={2}
          />
          <button
            type="submit"
            className={`absolute right-2 top-2 p-2 ${
              opinion.trim().length === 0
                ? "bg-gray-400"
                : "bg-cta-blue interact"
            } rounded-[8px] w-9 h-9`}
            style={{
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }}
            disabled={opinion.trim().length === 0}
          >
            {wait ? (
              <CircularProgress size={20} />
            ) : (
              <img src={arrowUp} alt="submit" className="w-5 h-5" />
            )}
          </button>
        </form>
      </div>
    </>
  );
};

export default EditForm;
