import React, { useRef, useState } from "react";
import Jessica from "assets/images/book.png";
import CloseIcon from "assets/images/post-edit/x-close.svg";
import { useNavigate } from "react-router-dom";
import useUserInfo from "hooks/useUserInfo";
import { useDispatch, useSelector } from "react-redux";
import Posts from "assets/images/others/posts.png";
import AnimationCard from "./AnimationCard";
import CarousalCard from "./CarousalCard";
import Check from "assets/images/others/Check.svg";
import { cutLinkedin, showErrorMessage } from "Utils";
import axios from "axios";
import { SERVER_URL } from "api";
import { setProfile, setRegisterEmail } from "store/mainSlice";
import useCookie from "hooks/useCookie";
import { setInterviewPicture } from "store/interviewSlice";

const CircleStartPopup = ({ setOpenPopup }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile, isAuth } = useUserInfo();
  const { fullName, mainTopic } = useSelector((state) => state.circle);
  const [emailValue, setEmailValue] = useState("");
  const popupRef = useRef(null);
  const closePopup = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target))
      setOpenPopup(false);
  };
  const queries = new URLSearchParams(window.location.search);
  const isCompanyLinkedin = (() => {
    return (
      !!queries.get("linkedin") && queries.get("linkedin").includes("company/")
    );
  })();
  const firstName =
    profile.firstName || isCompanyLinkedin ? "You" : fullName.split(" ")[0];

  const neededInfo = !isAuth && !profile.linkedin;
  const neededLinkedin =
    !profile.linkedin && (!queries.get("linkedin") || isCompanyLinkedin);
  const [wait, setWait] = useState(false);
  const [linkedinValue, setLinkedinValue] = useState("");
  const { setCookieEmail } = useCookie();
  const handleSubmit = async (e) => {
    e?.preventDefault?.();
    if (wait) return;
    const linkedin = neededLinkedin
      ? linkedinValue
      : profile.linkedin || queries.get("linkedin");
    const email = neededInfo ? emailValue : profile.email;
    if (!email?.trim()) {
      showErrorMessage("Email is required");
      return;
    }
    if (!linkedin?.trim()) {
      showErrorMessage("linkedin is required");
      return;
    }
    setWait(true);
    const cutLinkedinUrl = cutLinkedin(linkedin);
    let newLinkedinProfile = null;
    let picture = "";
    if (!isAuth) {
      try {
        const response = await axios.get(
          `${SERVER_URL}/services/linkedin/${cutLinkedinUrl}`
        );
        const {
          officialFirstName,
          officialLastName,
          company,
          headline,
        } = response.data;

        picture = response.data.picture;

        newLinkedinProfile = {
          firstName: officialFirstName,
          officialFirstName,
          officialLastName,
          company,
          email: email.trim(),
          linkedin: cutLinkedin,
          headline,
          // title
        };
        // rest of the validation and submission logic here
      } catch (error) {
        console.error(error);
        if (error.response?.status === 404) {
          showErrorMessage("LinkedIn URL is invalid");
        } else
          showErrorMessage(
            error.response?.data?.message || "Server Error, please try again."
          );
        // handle the error, display a message to the user, etc.
        setWait(false);
        return;
      }
    }
    try {
      const response = await axios.post(
        `${SERVER_URL}/interview-sessions/create`,
        {
          expertDetails: {
            firstName,
            email,
            linkedinProfile: newLinkedinProfile || {
              officialFirstName: profile.officialFirstName,
              officialLastName: profile.officialLastName,
              company: profile.company,
              picture: profile.picture,
              headline: profile.headline,
            },
            linkedin,
          },
          interviewDetails: {
            questionOrTopic: mainTopic,
            scheduleTime: {
              year: new Date().getFullYear(),
              month: new Date().getMonth(),
              day: new Date().getDate(),
              hour: -1,
              minute: -1,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            recurring: false,
          },
        },
        { timeout: 100 * 1000 }
      );
      dispatch(setRegisterEmail(email));
      dispatch(setInterviewPicture(picture));
      dispatch(setProfile(newLinkedinProfile));
      setCookieEmail(email);
      navigate(`/meeting/${response.data.interviewId}`);
    } catch (error) {
      console.log(error);
      showErrorMessage(
        error.response?.data?.message || "Internal server error"
      );
    }
    setWait(false);
  };
  return (
    <div
      className="fixed w-full h-full overflow-auto bg-[#131313aa] flex items-center justify-center z-[100] top-0 left-0"
      onClick={closePopup}
    >
      <div className="max-h-full overflow-scroll p-4 w-full">
        <div
          className="relative left-1/2 -translate-x-1/2 rounded-[30px] overflow-hidden bg-off-white-light w-full max-w-[700px] lg:max-w-[1328px] grid grid-cols-1 lg:grid-cols-2 items-center gap-8 py-6 pt-10 px-4 sm:px-6"
          style={{
            boxShadow:
              "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
          }}
          ref={popupRef}
        >
          <div className="absolute right-4 top-4 cursor-pointer z-[11]">
            <img
              src={CloseIcon}
              alt="close"
              onClick={() => setOpenPopup(false)}
            />
          </div>
          <div className="rounded-[30px] bg-off-white-super-light flex flex-col p-4 items-center gap-4">
            <div className="text-center text-black-bg font-eighties-it text-[28px] sm:text-[36px] leading-[103%]">
              Your call is ready to go!
            </div>
            <div className="w-full gap-3 flex-col sm:flex-row lg:gap-5 flex items-center justify-start">
              <img
                src={Jessica}
                alt="jessica"
                className="flex-shrink-0 w-24 h-28"
              />
              <div className="flex flex-col items-start justify-center gap-1">
                <div className="font-eighties text-[20px] sm:text-[27px] font-[500]">{`${firstName} <> Jessica`}</div>
                <div className="font-figtree text-[14px] sm:text-[16px] font-[400] leading-[120%]">
                  In the call we will disucss your experise , in a
                  video-chat...... dddddddd .dddddddd .dddddddd .dddddddd dddddd
                  ddd dddddddddd dddddddd ddddd
                </div>
              </div>
            </div>
            <div className="rounded-[30px] border border-off-white-dark w-full flex items-center py-4 gap-1 min-h-[100px]">
              <div className="flex-1 flex items-center justify-center gap-2">
                <div className="flex items-center gap-2 justify-center">
                  <div className="rounded-full w-6 h-6 flex-shrink-0 bg-[#FFA144] flex items-center justify-center">
                    <img src={Check} alt="check" />
                  </div>
                  <div className="text-text-primary font-figtree  max-400:text-[12px] text-[16px] leading-[150%] tracking-[0.15px]">
                    10 minutes <br /> Interview
                  </div>
                </div>
                <div className="w-3 sm:w-5 lg:w-7 xl:w-9 h-[2px] flex-shrink-0 bg-gray-400"></div>
                <div className="flex items-center gap-2">
                  <div className="rounded-full w-6 h-6 flex-shrink-0 bg-[#00C16E] flex items-center justify-center">
                    <img src={Check} alt="check" />
                  </div>
                  <div className="text-text-primary font-figtree max-400:text-[12px] text-[16px] sm:text-[16px] leading-[150%] tracking-[0.15px]">
                    5 Posts, <br /> 2 Articles
                  </div>
                </div>
              </div>
              <img
                src={Posts}
                alt="posts"
                className="h-auto w-[40%] hidden sm:block"
              />
            </div>
            <div className="w-full flex flex-col gap-6 items-start">
              <span className="text-black-bg font-figtree text-[16px] font-[500] leading-[225%]">
                Chosen Topic:
              </span>
              <div className="relative w-full">
                <div className="absolute top-0 left-0 -translate-y-[70%] px-2.5 py-1 font-roboto text-text-primary tex-[13px] leading-[138%] tracking-[0.16%] rounded-[16px] bg-purple-lavender">
                  Custom
                </div>
                <div className="py-3 px-4 rounded-[30px] text-text-primary font-figtree text-[16px] leading-[150%] tracking-[0.15px] bg-off-white-base w-full overflow-hidden">
                  {mainTopic}
                </div>
              </div>
            </div>
            {neededInfo && (
              <form
                onSubmit={handleSubmit}
                className="w-full flex flex-col gap-2"
              >
                <input
                  type="text"
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  id="email"
                  placeholder="Email"
                  className="px-3 py-4 w-full placeholder:text-text-secondary text-text-primary font-figtree text-[16px] leading-[150%] tracking-[0.15px] rounded-[30px]"
                />
                {neededLinkedin && (
                  <input
                    type="text"
                    value={linkedinValue}
                    onChange={(e) => setLinkedinValue(e.target.value)}
                    id="linkedin"
                    placeholder="Linkedin"
                    className="px-3 py-4 w-full placeholder:text-text-secondary text-text-primary font-figtree text-[16px] leading-[150%] tracking-[0.15px] rounded-[30px]"
                  />
                )}
              </form>
            )}
            <div className="w-full flex items-center justify-center flex-wrap gap-3">
              <button
                className="flex items-center justify-center gap-2 w-[258px] max-w-full rounded-[40px] text-white font-inter text-[16px] font-semibold leading-[24px] text-center py-2 px-4 bg-primary-main interact"
                onClick={handleSubmit}
                disabled={wait}
              >
                {wait ? "Wait..." : "Start Call Now"}
              </button>
            </div>
          </div>
          <div className="h-full flex-col gap-4 lg:gap-6 hidden lg:flex">
            <AnimationCard className="hidden md:flex flex-1" />
            <CarousalCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircleStartPopup;
