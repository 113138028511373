import React, { useEffect, useState } from "react";

const Loader = ({ className = "" }) => {
  const [opacities, setOpacities] = useState([
    1, 0.85, 0.75, 0.65, 0.45, 0.35, 0.25, 0.1,
  ]);
  useEffect(() => {
    const timer = setInterval(() => {
      setOpacities((prev) => [...prev.slice(1), prev[0]]);
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_4063_28251)">
        <circle
          cx="16"
          cy="3"
          r="3"
          fill="#0E24D6"
          fillOpacity={opacities[0]}
        />
        <circle
          cx="25.9"
          cy="7.5"
          r="3"
          fill="#0E24D6"
          fillOpacity={opacities[1]}
        />
        <circle
          cx="29"
          cy="16"
          r="3"
          fill="#0E24D6"
          fillOpacity={opacities[2]}
        />
        <circle
          cx="25.9"
          cy="24.5"
          r="3"
          fill="#0E24D6"
          fillOpacity={opacities[3]}
        />
        <circle
          cx="16"
          cy="29"
          r="3"
          fill="#0E24D6"
          fillOpacity={opacities[4]}
        />
        <circle
          cx="6.1"
          cy="24.5"
          r="3"
          fill="#0E24D6"
          fillOpacity={opacities[5]}
        />
        <circle
          cx="3"
          cy="16"
          r="3"
          fill="#0E24D6"
          fillOpacity={opacities[6]}
        />
        <circle
          cx="6.1"
          cy="7.5"
          r="3"
          fill="#0E24D6"
          fillOpacity={opacities[7]}
        />
      </g>
      <defs>
        <clipPath id="clip0_4063_28251">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Loader;
