import React, { useEffect, useMemo, useRef, useState } from "react";
import DefaultUser from "assets/images/default-user.png";
import useUserInfo from "hooks/useUserInfo";
import useInterview from "hooks/useInterview";

const UserAvatar = ({
  className,
  alt = "user",
  defaultUser = DefaultUser,
  firstPicture,
  secondLowPicture,
  secondHighPicture,
  useSecondHighPicture,
}) => {
  const {
    profile: { picture: profilePicture },
  } = useUserInfo();
  const { interviewPicture, guestMode } = useInterview();
  const firstP = useMemo(
    () => firstPicture || interviewPicture,
    [firstPicture, interviewPicture]
  );
  const secondHighP = useMemo(
    () => secondHighPicture || guestMode?.guest?.picture,
    [guestMode?.guest?.picture, secondHighPicture]
  );
  const secondLowP = useMemo(
    () => secondLowPicture || profilePicture,
    [profilePicture, secondLowPicture]
  );
  const useSecondHighP = useMemo(
    () =>
      useSecondHighPicture === undefined
        ? !!guestMode?.guest
        : !!useSecondHighPicture,
    [guestMode?.guest, useSecondHighPicture]
  );
  const [imageLevel, setImageLevel] = useState(0);
  const imgRef = useRef(null);
  const imageRef = useRef();
  const checkRef = useRef(0);
  const loadImage = (src, fallback, checkNumber) => {
    const img = new Image();
    img.src = src;
    img.onerror = () => {
      if (fallback !== undefined && checkNumber === checkRef.current) {
        setImageLevel(fallback);
      }
    };
  };

  const image = useMemo(() => {
    console.log(imageLevel);

    if (imageLevel === 2) return firstP;
    if (imageLevel === 1) return secondHighP || secondLowP;
    return defaultUser;
  }, [defaultUser, firstP, imageLevel, secondHighP, secondLowP]);
  useEffect(() => {
    if (useSecondHighP) {
      setImageLevel(1);
    } else {
      setImageLevel(2);
    }
  }, [useSecondHighP, firstP, secondHighP, secondLowP]);
  useEffect(() => {
    const num = Date.now();
    checkRef.current = num;
    if (imageLevel === 2) loadImage(firstP, 1, num);
    if (imageLevel === 1) loadImage(secondHighP || secondLowP, 0, num) ;
  }, [firstP, imageLevel, secondHighP, secondLowP])
  useEffect(() => {
    if (imageRef.current)
      imageRef.current.style.backgroundImage = `url(${image})`;
  }, [image]);

  return (
    // <img
    //   src={image}
    //   alt={alt}
    //   className={`avatar ${className}`}
    //   onError={handleError}
    //   ref={imgRef}
    // />
    <div
      className={`avatar ${className}`}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      ref={imgRef}
      role="img"
      aria-label={alt}
    ></div>
  );
};

export default UserAvatar;
