import React, { useCallback, useMemo, useRef, useState } from "react";

import { getInterviewDate, INTERVIEW_STATUS, showErrorMessage } from "Utils";
import Avatar from "components/Avatar";
import { setFetchedPosts } from "store/postSlice";
import { initInterviewData, setInterviewPicture } from "store/interviewSlice";

import FinishedIcon from "assets/images/sidebar/Finished Icon.svg";
import NotFinishedIcon from "assets/images/sidebar/Not Finished Icon.svg";
import CalendarIcon from "assets/images/sidebar/Calendar Icon.svg";
// import MoreIcon from "assets/images/sidebar/More Options Icon.svg";
import TrashIcon from "assets/images/sidebar/trash-01.svg";
import JessicaLogo from "assets/images/meeting/meeting-jessica.png";
// import Tooltip from "components/Tooltip/Tooltip";
import Tooltip from "components/Tooltip";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useUserInfo from "hooks/useUserInfo";
import { setInterviews, setIsSlim, setSchedules } from "store/mainSlice";
import UserAvatar from "components/UserAvatar";
import axios from "axios";
import { SERVER_URL } from "api";
import useInterview from "hooks/useInterview";
import useManageAvatar from "hooks/useManageAvatar";
import EditIcon from "assets/images/navbar/edit-02.svg";

const InterviewSession = ({ interview }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { profile, interviews, schedules } = useUserInfo();
  const { interviewId: currentInterviewId } = useInterview();
  const { firstName } = profile;
  const [deleting, setDeleting] = useState(false);
  const imageInputRef = useRef(null);
  const { uploadInterviewImage, isUploading } = useManageAvatar();
  const guestMode = interview.guestMode;
  const interviewId = interview._id;
  const slimOnMobile = useCallback(() => {
    if (window.innerWidth <= 768) {
      dispatch(setIsSlim(true));
    }
  }, [dispatch]);

  const isInCallsPage = useMemo(
    () => location.pathname.includes("/my-calls"),
    [location]
  );

  const deleteInterview = async (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (deleting) return;
    setDeleting(true);
    try {
      await axios.delete(
        `${SERVER_URL}/interview-sessions/${interviewId}/remove`
      );
      dispatch(setInterviews(interviews.filter((i) => i._id !== interviewId)));
      if (interviewId === currentInterviewId) navigate("/schedule");
    } catch (error) {
      console.log(error);
      showErrorMessage(
        "Failed to delete the interview session. Please try again."
      );
    }
    setDeleting(false);
  };

  const handleUpload = async (event) => {
    console.log(event);

    const file = event.target.files[0];
    if (!file) return;
    try {
      const newPicture = await uploadInterviewImage(file, interviewId, profile.email, !guestMode?.guest);
      if (!newPicture) {
        showErrorMessage("Failed to upload new avatar, please try again");
        event.target.value = "";
        return;
      }
      if (guestMode?.guest) {
        const newInterview = {
          ...interview,
        };
        // if (newInterview.guestMode?.guest) {
        newInterview.guestMode = {
          ...newInterview.guestMode,
          guest: { ...newInterview.guestMode.guest, picture: newPicture },
        };
        // } else {
        //   newInterview.picture = newPicture;
        // }
        const newInterviews = [...interviews];
        const interviewIndex = newInterviews.findIndex(
          (i) => i._id === interviewId
        );
        if (interviewIndex > -1 && interviewIndex < newInterviews.length) {
          newInterviews.splice(interviewIndex, 1, newInterview);
          dispatch(setInterviews(newInterviews));
        }
        if (interviewId === currentInterviewId) {
          dispatch(setInterviewPicture(newPicture));
        }
      } else {
        const newInterviews = [...interviews].map((i) => ({
          ...i,
          picture: newPicture,
        }));
        dispatch(setInterviews(newInterviews));
        const newSchedules = [...schedules].map((i) => ({
          ...i,
          picture: newPicture,
        }));
        dispatch(setSchedules(newSchedules));
        dispatch(setInterviewPicture(newPicture));
      }
    } catch (error) {
      console.log(error);
    }
    event.target.value = "";
  };

  const openSelectImage = (e) => {
    console.log(e, imageInputRef.current);

    e?.stopPropagation();
    e?.preventDefault();
    if (!imageInputRef.current || isUploading) return;
    imageInputRef.current.click();
  };

  return (
    <>
      <div
        className="hidden"
        onClick={(e) => {
          console.log(e);
        }}
      >
        <input
          type="file"
          onChange={handleUpload}
          ref={imageInputRef}
          accept="image/*"
        />
      </div>
      <Link
        to={
          interview.status === INTERVIEW_STATUS.DONE
            ? "/posts/" + interview._id
            : "/meeting/" + interview._id
        }
        className="sidebar-menu-item-child-link bg-black"
        onClick={() => {
          dispatch(setFetchedPosts(false));
          dispatch(initInterviewData());
          slimOnMobile();
        }}
      >
        <div
          className={`py-2.5 px-[14px] flex gap-2 cursor-pointer items-center justify-between ${
            currentInterviewId === interview._id && !isInCallsPage
              ? "sidebar-menu-active-child"
              : ""
          }`}
        >
          <div className="flex flex-col gap-2 cursor-pointer items-start">
            <div className="sidebar-menu-item-child-text flex items-center gap-1">
              <div className="flex items-center justify-center flex-shrink-0">
                <Tooltip
                  content={guestMode ? guestMode?.guest?.firstName : firstName}
                  className="flex-shrink-0 font-semibold px-[10px] py-[8px]"
                >
                  <button
                    onClick={openSelectImage}
                    className={`rounded-full border-none outline-none relative overflow-hidden ${
                      isUploading ? "opacity-60" : "opacity-100"
                    }`}
                    disabled={isUploading}
                  >
                    <UserAvatar
                      className="avatar size-8"
                      alt="user"
                      firstPicture={interview.picture}
                      secondHighPicture={interview.guestMode?.guest?.picture}
                      useSecondHighPicture={!!interview.guestMode?.guest}
                    />
                    {isUploading || (
                      <div className="absolute size-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center bg-[#fff7] opacity-0 hover:opacity-100">
                        <img src={EditIcon} alt="edit" className="size-4" />
                      </div>
                    )}
                  </button>
                </Tooltip>
                <Tooltip
                  // content={guestMode?.assistant?.name || "Jessica"}
                  content={"Jessica"}
                  className="flex-shrink-0 font-semibold px-[10px] py-[8px]"
                >
                  <div className=" -ml-2">
                    <Avatar
                      className="avatar size-8"
                      src={guestMode?.assistant?.picture || JessicaLogo}
                      alt="assistant"
                      defaultUser={JessicaLogo}
                    />
                  </div>
                </Tooltip>
              </div>
              <span>{interview.topic}</span>
            </div>
            <div className="sidebar-menu-item-child-date flex items-center gap-3 flex-wrap">
              <div className="flex items-center gap-1">
                {interview.status === INTERVIEW_STATUS.DONE ? (
                  <>
                    <img
                      src={FinishedIcon}
                      alt="Finished"
                      className="flex-shrink-0  w-4 h-4"
                    />
                    <span className="flex-shrink-0">Finished</span>
                  </>
                ) : (
                  <>
                    <img
                      src={NotFinishedIcon}
                      alt="Finished"
                      className="flex-shrink-0  w-4 h-4"
                    />
                    <span className="flex-shrink-0">Not Finished</span>
                  </>
                )}
              </div>
              <div className="flex items-center gap-1">
                <img
                  src={CalendarIcon}
                  alt="date"
                  className="flex-shrink-0  w-4 h-4"
                />
                <span className="flex-shrink-0">{`Created: ${getInterviewDate(
                  interview.schedule
                )}`}</span>
              </div>
            </div>
          </div>
          <img
            src={TrashIcon}
            alt="more"
            className={`flex-shrink-0 size-5 more-btn opacity-80 ${
              currentInterviewId === interview._id ? "block" : "hidden"
            }`}
            onClick={deleteInterview}
          />
        </div>
      </Link>
    </>
  );
};

export default InterviewSession;
