import React, { useState } from "react";
import CloseIcon from "assets/images/post-edit/x-close.svg";
import useUserInfo from "hooks/useUserInfo";
import { useSelector } from "react-redux";
import { capitalize } from "Utils";
import Clock from "assets/images/dashboard/clock.svg";
import useInterview from "hooks/useInterview";
import Back from "assets/images/dashboard/ArrowBack.svg";
import Linkedin from "assets/images/dashboard/linkedin-lg.svg";
import Twitter from "assets/images/dashboard/X Icon.svg";
import Target from "assets/images/dashboard/target-04.svg";
import { Rating } from "@mui/material";
import { StarOutline } from "@mui/icons-material";
import UserAvatar from "components/UserAvatar";

const ScorePopup = ({ setOpenPopup }) => {
  const { profile } = useUserInfo();
  const { totalWordsCount, totalDuration, AvgAnswerLength } = useSelector(
    (state) => state.post
  );
  const { score, topic, evaluation, guestMode } = useInterview();
  //   const popupRef = useRef(null);
  //   const closePopup = (e) => {
  //     if (popupRef.current && !popupRef.current.contains(e.target))
  //       setOpenPopup(false);
  //   };
  const [isShare, setIsShare] = useState(false);
  const durationString =
    totalDuration >= 60
      ? `${(totalDuration / 60).toFixed(0)}m`
      : `${totalDuration.toFixed(0)}s`;
  const answerString = `${(AvgAnswerLength / 60).toFixed(2)}`;
  const text = `I'm officially a ${Math.round(score)}-star expert on "${topic}
                    " thanks to @Jessica! 🙌`;

  return (
    <div className="fixed w-full h-full overflow-auto bg-[#131313aa] flex items-center justify-center z-[100] top-0 left-0">
      <div className="max-h-full overflow-y-auto scroll-dark p-4 w-full">
        <div
          className="relative left-1/2 -translate-x-1/2 rounded-[30px] overflow-hidden bg-off-white-light w-full max-w-[604px]  flex flex-col items-center gap-2 sm:gap-4 md:gap-8 py-4 sm:py-6 pt-10 px-4 sm:px-6"
          style={{
            boxShadow:
              "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
          }}
          // ref={popupRef}
        >
          <div className="rounded-[30px] flex flex-col items-center gap-4">
            <div className="flex w-full items-center justify-between right-4 top-4 cursor-pointer z-[11]">
              <div className="flex items-center gap-2 p-1 font-figtree text-[13px] font-[700] leading-[100%] tracking-[0.46px] text-primary-main link interact" onClick={() => {
                setIsShare(false);
              }} style={{visibility: isShare ? "visible" : "hidden"}}>
                <img src={Back} alt="back" />
                <span>Back</span>
              </div>
              <img
                src={CloseIcon}
                alt="close"
                onClick={() => setOpenPopup(false)}
              />
            </div>
            {isShare && (
              <div className="text-center text-gray-900 font-figtree text-[18px] sm:text-[20px] leading-[180%] tracking-[-0.2px] font-[700]">
                Share your Achievement
              </div>
            )}
            <div className="w-full gap-4 flex flex-col items-center font-figtree text-gray-900 leading-[111%] tracking-[-0.18px]">
              <UserAvatar
                alt="user"
                className="flex-shrink-0 size-28"
              />
              {isShare || (
                <>
                  <span className="font-bold text-center">
                    {capitalize(guestMode?.guest?.firstName || profile.firstName)}, Thank You for Sharing Your
                    Expertise 🎉
                  </span>
                  <span className="text-center">
                    Your interview score below is a measure of your expertise.
                    Higher scores indicate a deeper understanding of your niche
                    and more valuable insights for your audience.
                  </span>
                </>
              )}
              {isShare && (
                <>
                  <span className="text-[20px] tracking-[-0.2px] leading-[30px] text-center ">
                    {text}
                  </span>
                  <span className="text-[20px] tracking-[-0.2px] leading-[30px] text-center">
                    Stay tuned for the awesome content we created together!
                  </span>
                </>
              )}
            </div>
          </div>
          {isShare && (
            <div className="w-full flex justify-center items-center gap-5 pb-8">
              <a href="https://linkedin.com">
                <img
                  src={Linkedin}
                  alt="linkedin"
                  className="size-[90px] flex-shrink-0"
                />
              </a>
              <a
                href={`https://x.com/share?text=${encodeURIComponent(
                  text
                )}&url=${window.location.origin}`}
              >
                <img
                  src={Twitter}
                  alt="x"
                  className="size-[90px] flex-shrink-0"
                />
              </a>
            </div>
          )}
          {isShare || (
            <div className="w-full flex flex-col items-center gap-2 sm:gap-4">
              <div className="p-4 rounded-[16px] gap-1 items-center border border-light-blue bg-[#F5FAFF] z-[1] flex flex-col">
                <img src={Target} alt="target" />
                <div className="flex items-center font-figtree text-[20px] font-[500] leading-[112%] gap-1 justify-center">
                  Your Interview Score
                </div>
                <Rating
                  value={Number.parseFloat(score)}
                  readOnly
                  precision={0.1}
                  emptyIcon={<StarOutline sx={{ fill: "#EDC515" }} />}
                />
                <div className="flex items-center font-figtree text-[20px] font-[500] leading-[112%] gap-1 justify-center">
                  {evaluation}
                </div>
              </div>
              <div className="w-full grid grid-cols-3 gap-2 sm:gap-4">
                <div className="border border-light-blue rounded-[12px] bg-[#F5FAFF] flex flex-col items-start gap-1 p-2 sm:p-4 overflow-hidden">
                  <div className="text-gray-500 font-inter text-[10px] sm:text-[12px] font-[500] leading-[150%] whitespace-nowrap">
                    <span className="max-400:hidden">Interview</span> Length
                  </div>
                  <div className="flex gap-1 sm:gap-2 items-center justify-start">
                    <img
                      src={Clock}
                      alt="time"
                      className="size-6 sm:size-8 max-400:size-4 flex-shrink-0"
                    />
                    <span className="text-gray-900 font-inter text-[24px] sm:text-[32px] max-400:text-[18px] font-[500] leading-[93.75%]">
                      {durationString}
                    </span>
                  </div>
                </div>
                <div className="border border-light-blue rounded-[12px] bg-[#F5FAFF] flex flex-col items-start gap-1 p-2 sm:p-4 overflow-hidden">
                  <div className="text-gray-500 font-inter text-[10px] sm:text-[12px] font-[500] leading-[150%] whitespace-nowrap">
                    Word Count
                  </div>
                  <div className="flex gap-1 sm:gap-2 items-center justify-start">
                    <img
                      src={Clock}
                      alt="time"
                      className="size-6 sm:size-8 max-400:size-4 flex-shrink-0"
                    />
                    <span className="text-gray-900 font-inter text-[24px] sm:text-[32px] max-400:text-[18px] font-[500] leading-[93.75%]">
                      {totalWordsCount}
                    </span>
                  </div>
                </div>
                <div className="border border-light-blue rounded-[12px] bg-[#F5FAFF] flex flex-col items-start gap-1 p-2 sm:p-4 overflow-hidden">
                  <div className="text-gray-500 font-inter text-[10px] sm:text-[12px] font-[500] leading-[150%] whitespace-nowrap">
                    Avg. answer<span className="max-400:hidden">/ min</span>
                  </div>
                  <div className="flex gap-1 sm:gap-2 items-center justify-start">
                    <img
                      src={Clock}
                      alt="time"
                      className="size-6 sm:size-8 max-400:size-4 flex-shrink-0"
                    />
                    <span className="text-gray-900 font-inter text-[24px] sm:text-[32px] max-400:text-[18px] font-[500] leading-[93.75%]">
                      {answerString}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isShare || (
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 sm:gap-4">
              <button
                className="py-2 px-5 rounded-[40px] border border-primary-main text-primary-main font-inter text-[14px] sm:text-[16px] font-[600] leading-[150%] text-center"
                onClick={() => setIsShare(true)}
              >
                Share Your Achievement
              </button>
              <button
                className="py-2 px-5 rounded-[40px] border border-primary-main bg-primary-main text-white font-inter text-[14px] sm:text-[16px] font-[600] leading-[150%] text-center"
                onClick={() => setOpenPopup(false)}
              >
                View Your Posts
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScorePopup;
