import { INTERVIEW_STATUS, showErrorMessage } from "Utils";
import { SERVER_URL } from "api";
import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setInterview, setInterviewId } from "store/manageSlice";
import Confirm from "./Confirm";
import { setProfile } from "store/mainSlice";
import { setInterviewPicture } from "store/interviewSlice";

const Manage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { interviewId } = useParams();
  useEffect(() => {
    const fetchInterview = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/interview-sessions/${interviewId}/data`
        );
        const interview = response.data;
        if (
          interview.status < INTERVIEW_STATUS.DONE &&
          interview.status > INTERVIEW_STATUS.CREATED
        ) {
          navigate(`/meeting/${interviewId}`);
          return;
        }
        dispatch(setInterviewId(interviewId));
        dispatch(setInterview(interview));
        const {
          firstName,
          officialFirstName,
          officialLastName,
          company,
          email,
          picture,
          linkedin,
          headline,
        } = interview;
        dispatch(
          setProfile({
            firstName,
            officialFirstName,
            officialLastName,
            company,
            email,
            linkedin,
            headline,
          })
        );
        dispatch(setInterviewPicture(picture));
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 400) {
          // showErrorMessage("The interview link is not valid.");
          navigate("/not-found");
          return;
        }
        showErrorMessage(
          "Failed to connect to the server. Please refresh the page."
        );
        navigate("/not-found");
      }
    };
    fetchInterview();
  }, [dispatch, interviewId, navigate]);
  if (!interviewId) return <></>;
  return <Confirm />;
};

export default Manage;
