import React from "react";
import Logo from "assets/images/Logo-white.png";
import JessicaLogo from "assets/images/meeting/meeting-jessica.png";
import "./PostAside.css";
import { useSelector } from "react-redux";
import useUserInfo from "hooks/useUserInfo";
import useInterview from "hooks/useInterview";
import Avatar from "components/Avatar";
import UserAvatar from "components/UserAvatar";

const PostAside = ({ onClick = () => {} }) => {
  const { posts, articles } = useSelector((state) => state.post);
  const { topic, guestMode } = useInterview();
  const { firstName } = useUserInfo().profile;
  return (
    <aside className="aside" onClick={onClick}>
      <div>
        <div className="avatars">
          <Avatar
            className="avatar"
            src={guestMode?.assistant?.picture || JessicaLogo}
            alt="assistant"
            defaultUser={JessicaLogo}
          />
          <UserAvatar
            className="avatar user"
            alt="user"
          />
        </div>
        <div className="topic">
          <strong className="capitalize">
            {guestMode?.assistant.name ||"Jessica"} &lt;&gt; {guestMode ? guestMode?.guest?.firstName : firstName}
          </strong>{" "}
          <br />
          Topic: {topic}
        </div>
        <div className="description">
          <div>Interview post:</div>
          <div>{posts?.length + articles?.length} Assets created</div>
          <div>
            <div>
              <strong>{posts?.length} posts</strong> &{" "}
              <strong>{articles?.length} articles</strong>
            </div>
            {/* <div>
              <strong>3tag #tag #tag</strong>
            </div> */}
          </div>
          {/* <div>
            <div>
              <strong>{articles?.length} articles</strong>
            </div>
            <div>
              <strong>3tag #tag #tag</strong>
            </div>
          </div> */}
        </div>
      </div>
      <div className="copyright">
        <div>Powered by</div>
        <a
          href="https://www.q-ueue.ai/?utm_source=Jessica&utm_medium=powerby"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Logo} alt="logo-black-blue" className="link" />
        </a>
      </div>
    </aside>
  );
};

export default PostAside;
