import Button, { ButtonVariant } from "components/Button";
import React, { useEffect, useState } from "react";
import CopyIcon from "assets/images/dashboard/copy.svg";
import useUserInfo from "hooks/useUserInfo";
// import { useSelector } from "react-redux";
import Loader from "components/Loader";
import { md2plain, showErrorMessage, sleep } from "Utils";
import axios from "axios";
import { SERVER_URL } from "api";
import useInterview from "hooks/useInterview";
import CheckIcon from "assets/images/check.svg";

const CopyContent = ({ postId }) => {
  const { isAuth, isSubscribed } = useUserInfo();
  const [content, setContent] = useState("");
  const { interviewId } = useInterview();
  // const { posts, articles, threads, tweets, answers, reels } = useSelector(
  //   (state) => state.post
  // );
  const [copyingStep, setCopyingStep] = useState(0);
  // const fullyAvailable = [
  //   ...posts,
  //   ...articles,
  //   ...threads,
  //   ...tweets,
  //   ...answers,
  //   ...reels,
  // ].every((post) => !post.isOpenedFirst);
  useEffect(() => {
    (async () => {
      if (!isAuth && !isSubscribed) return;
      setCopyingStep(1);
      try {
        let response = null,
          count = 0;
        while (true) {
          response = await axios.get(
            `${SERVER_URL}/posts/${interviewId}/${postId}`
          );
          if (!response.data.isGenerating) break;
          count++;
          if (count > 120) break;
          await sleep(1000);
        }
        const contentToCopy =
          response.data.contents[response.data.contents.length - 1];
        setContent(contentToCopy);
      } catch (error) {
        console.log(error);
      }
      setCopyingStep(0);
    })();
  }, [interviewId, isAuth, isSubscribed, postId]);
  const copyContent = async () => {
    if (copyingStep !== 0) return;
    setCopyingStep(1);
    navigator.clipboard
      .writeText(md2plain(content)) // Copy the URL to the clipboard
      .then(() => {
        console.log("Content copied to clipboard: " + content);
        // showSuccessMessage("Content copied to clipboard.");
        setCopyingStep(2);
      })
      .catch((error) => {
        console.error("Failed to copy Content to clipboard: ", error);
        showErrorMessage("Failed to copy the content. Please try again");
        setCopyingStep(0);
      });
  };

  useEffect(() => {
    let timer = 0;
    if (copyingStep === 2) {
      timer = setTimeout(() => {
        setCopyingStep(0);
        timer = 0;
      }, 3000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [copyingStep]);

  return copyingStep === 1 ? (
    <Loader className="size-4" />
  ) : (
    <Button
      icon={copyingStep === 2 ? CheckIcon : CopyIcon}
      variant={ButtonVariant.GHOST}
      disabled={(!isAuth && !isSubscribed) || !content}
      onClick={copyContent}
      iconClassName="size-5"
    />
  );
};

export default CopyContent;
