import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import sidebarIcon from "assets/images/sidebar/sidebar-icon.svg";
import fullLogo from "assets/images/sidebar/full-logo.svg";
import burgerIcon from "assets/images/sidebar/burger-icon.svg";
import interviewIcon from "assets/images/sidebar/file-heart.svg";
import iconJessica from "assets/images/sidebar/icon-jessica.png";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

import useUserInfo from "hooks/useUserInfo";
import { useDispatch } from "react-redux";
import useCustomTheme from "hooks/useCustomTheme";
import { setIsSlim } from "store/mainSlice";
import FaceSmile from "assets/images/sidebar/face-smile.svg";
import FileX from "assets/images/sidebar/file-x-02.svg";
import UserAvatar from "components/UserAvatar";
import InterviewSession from "./InterivewSession";
import useInterview from "hooks/useInterview";
import { INTERVIEW_STATUS } from "Utils";

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isSlim } = useCustomTheme();
  const [isOpenedFinished, setIsOpenedFinished] = useState(true);
  const [isOpenedUnfinished, setIsOpenedUnfinished] = useState(false);
  const { profile } = useUserInfo();
  const { interviewPicture } = useInterview();
  const { interviews } = profile;
  const handleSlim = useCallback(() => {
    if (isSlim) {
      setIsOpenedFinished(false);
      setIsOpenedUnfinished(false);
    } else {
      setIsOpenedUnfinished(false);
      setIsOpenedFinished(true);
    }
  }, [isSlim]);
  const toggleSlimMode = () => {
    dispatch(setIsSlim(!isSlim));
    handleSlim();
  };

  useEffect(() => {
    handleSlim();
  }, [handleSlim]);

  const slimOnMobile = useCallback(() => {
    if (window.innerWidth <= 768) {
      dispatch(setIsSlim(true));
    }
  }, [dispatch]);

  const barRef = useRef(null);

  useEffect(() => {
    const closeSidebar = (event) => {
      if (barRef.current && !barRef.current.contains(event.target))
        slimOnMobile();
    };
    window.addEventListener("click", closeSidebar);
    return () => {
      window.removeEventListener("click", closeSidebar);
    };
  });

  const toggleMenuItemFinished = () => {
    setIsOpenedFinished((prev) => !prev);
  };

  const toggleMenuItemUnfinished = () => {
    setIsOpenedUnfinished((prev) => !prev);
  };

  const isInCallsPage = useMemo(
    () => location.pathname.includes("/my-calls"),
    [location]
  );

  const finishedInterviews = useMemo(
    () => interviews?.filter((i) => i.status === INTERVIEW_STATUS.DONE) || [],
    [interviews]
  );
  const unfinishedInterviews = useMemo(
    () =>
      interviews?.filter((i) => i.status === INTERVIEW_STATUS.INTERVIEWING) ||
      [],
    [interviews]
  );

  return (
    <div className={`sidebar ${isSlim ? "slim" : ""}`} ref={barRef}>
      <div className="sidebar-box scroll-dark">
        <div className="icon-container top-0" onClick={toggleSlimMode}>
          {isSlim ? (
            <img
              src={sidebarIcon}
              alt="Slim Logo"
              className="slim-logo flex-shrink-0"
            />
          ) : (
            <div className="full-logo-wrapper">
              <img src={burgerIcon} alt="Burger Icon" className="burger-icon" />
              <img src={fullLogo} alt="Full Logo" className="full-logo" />
            </div>
          )}
        </div>
        <div className="sidebar-menu-list relative">
          {/* <div className="sidebar-menu-item sticky top-[64px]"> */}
          <div className={`sidebar-menu-item-header ${isSlim ? "slim" : ""}`}>
            <div className="sidebar-menu-item-title">
              <img
                src={interviewIcon}
                alt="Interview Icon"
                className={`sidebar-menu-item-icon ${
                  isSlim ? "cursor-pointer" : ""
                }`}
                onClick={() => dispatch(setIsSlim(false))}
              />
              {!isSlim && (
                <p className="sidebar-menu-item-text">{`Completed Interviews (${finishedInterviews?.length ?? 0})`}</p>
              )}
            </div>
            {!isSlim && !!finishedInterviews?.length && (
              <div
                className="sidebar-menu-item-expander"
                onClick={toggleMenuItemFinished}
              >
                {isOpenedFinished ? (
                  <KeyboardArrowDown />
                ) : (
                  <KeyboardArrowRight />
                )}
              </div>
            )}
          </div>

          {isOpenedFinished && (
            <div className="sidebar-menu-item-content">
              {finishedInterviews &&
                finishedInterviews.map((interview, index) => (
                  <InterviewSession key={index} interview={interview} />
                ))}
            </div>
          )}
          {/* </div> */}
          {/* <div className="sidebar-menu-item sticky top-[118px] bottom-[calc(100%-130px)]"> */}
          <div
            className={`sidebar-menu-item-header ${isSlim ? "slim" : ""}`}
            style={{ top: "118px", bottom: "128px" }}
          >
            <div className="sidebar-menu-item-title">
              <img
                src={FileX}
                alt="Interview Icon"
                className={`sidebar-menu-item-icon ${
                  isSlim ? "cursor-pointer" : ""
                }`}
                onClick={() => dispatch(setIsSlim(false))}
              />
              {!isSlim && (
                <p className="sidebar-menu-item-text">{`Unfinished Interviews (${unfinishedInterviews?.length ?? 0})`}</p>
              )}
            </div>
            {!isSlim && !!unfinishedInterviews?.length && (
              <div
                className="sidebar-menu-item-expander"
                onClick={toggleMenuItemUnfinished}
              >
                {isOpenedUnfinished ? (
                  <KeyboardArrowDown />
                ) : (
                  <KeyboardArrowRight />
                )}
              </div>
            )}
          </div>

          {isOpenedUnfinished && (
            <div className="sidebar-menu-item-content">
              {unfinishedInterviews &&
                unfinishedInterviews.map((interview, index) => (
                  <InterviewSession key={index} interview={interview} />
                ))}
            </div>
          )}
          {/* </div> */}
          <Link
            to={"/my-calls"}
            className={`sidebar-menu-item sticky bottom-[76px]`}
          >
            <div
              className={`sidebar-menu-item-header ${isSlim ? "slim" : ""} ${
                isInCallsPage ? "sidebar-menu-active-child" : ""
              }`}
            >
              <div className="sidebar-menu-item-title">
                <img
                  src={FaceSmile}
                  alt="my calls"
                  className="sidebar-menu-item-icon"
                />
                {!isSlim && (
                  <p className="sidebar-menu-item-text">My Scheduled Calls</p>
                )}
              </div>
            </div>
          </Link>
          <div className="sidebar-menu-item sticky bottom-0">
            <div className={`sidebar-menu-item-header ${isSlim ? "slim" : ""}`}>
              <div
                className={`sidebar-menu-item-title ${isSlim ? "" : "profile"}`}
              >
                <UserAvatar
                  alt="avatar"
                  className="size-[48px] rounded-full"
                  secondHighPicture={Math.random()}
                  useSecondHighPicture={false}
                  firstPicture={profile.picture}
                  secondLowPicture={interviewPicture}
                />
                {!isSlim && (
                  <img
                    src={iconJessica}
                    alt="Profile Icon"
                    className="sidebar-menu-profile-jessica"
                  />
                )}
              </div>
              {!isSlim && (
                <Link
                  to="/schedule"
                  className="sidebar-menu-schedule-call"
                  onClick={() => {
                    slimOnMobile();
                  }}
                >
                  <p className="sidebar-menu-schedule-call-text">
                    Schedule Call
                  </p>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
