import React, { useEffect, useState } from "react";
import Tooltip from "components/Tooltip";
import infoIcon from "assets/images/dashboard/Info Icon.svg";
import SwitchButton from "components/Switch/Switch";
import useInterview from "hooks/useInterview";
import PodcastPopup from "../PodcastPopup/PodcastPopup";
import Button, { ButtonVariant } from "components/Button";
import FullAudioPlyer from "../FullAudioPlayer";
import Loader from "components/Loader";
import { SERVER_URL } from "api";
import axios from "axios";
import { showErrorMessage, sleep } from "Utils";
import { useDispatch } from "react-redux";
import { setPodcast } from "store/postSlice";
import { useSelector } from "react-redux";
import UserAvatar from "components/UserAvatar";

const PodcastCard = () => {
  // const [allowPodcast, setAllowPodcast] = useState(false);
  const dispatch = useDispatch();
  const [openPodcastPopup, setOpenPodcastPopup] = useState(false);
  const { podcastUrl, interviewId } = useInterview();
  const { podcast } = useSelector(state => state.post);
  
  useEffect(() => {
    const fetchPodcast = async () => {
      try {
        let response = null, count = 0;
        while (true) {
          response = await axios.get(`${SERVER_URL}/interview-sessions/${interviewId}/podcast`);
          if (response.data.highlight_audio_url && response.data.full_audio_url) break;
          count++;
          if (count > 120) {
            console.log("Failed to generate the podcast : ", count);
            return;
          }
          await sleep(1000);
        }
        dispatch(setPodcast(response.data))
      } catch (error) {
        console.log(error);
        showErrorMessage("Failed to load your podcast details. Please refresh the page and try again.")
      }
    }
    if ((!podcast || !podcast.highlight_audio_url || !podcast.full_audio_url) && interviewId) fetchPodcast();
  }, [dispatch, interviewId, podcast]);

  return (
    <div
      className="border border-off-white-dark rounded-[20px] p-3 h-[168px] min-h-full flex flex-col items-center relative overflow-hidden"
      style={{ background: "linear-gradient(to left, #FE896830, #0000)" }}
    >
      <div className="w-full flex-1 flex items-start gap-2 pt-1">
        <UserAvatar
          alt="quote"
          className="size-12 flex-shrink-0"
        />
        <div className="flex flex-1 flex-col items-start gap-2">
          <FullAudioPlyer />
          <div className="font-figtree text-[14px] leading-[120%]">
            You need to know how to lead yourself before you can lead anybody
            else.
          </div>
        </div>
      </div>
      <div className="w-full flex items-center">
        <a href={podcastUrl} onClick={(e) => e.preventDefault()}>
          <Button
            text="Go to Podcast"
            variant={ButtonVariant.OUTLINE}
            onClick={() => {
              window.open(podcastUrl, "_black");
            }}
            disabled={!podcastUrl}
            className="py-1 px-2.5 font-figtree text-[13px] font-[700] leading-[22px] tracking-[0.46px] rounded-[40px]"
          />
        </a>

        <div className="flex-1 relative flex justify-end gap-0.5 items-center">
          <span className="text-gray-600 font-figtree text-[12px] leading-[143%] tracking-[0.15px]">
            I agree to be featured on Jessica's show
          </span>
          <SwitchButton
            checked={!!podcastUrl}
            setChecked={(value) => {
              if (value) setOpenPodcastPopup(true);
            }}
          />
          <Tooltip
            content={
              "Your interview will be streamed for all to hear. Ready to share?"
            }
            side={"top"}
            className="py-2 px-3 rounded-lg font-inter text-[12px] font-[600] leading-[18px]"
          >
            <img src={infoIcon} alt="info" className="size-[18px]" />
          </Tooltip>
        </div>
      </div>
      {openPodcastPopup && <PodcastPopup setOpenPopup={setOpenPodcastPopup} />}
      {!!(podcast?.highlight_audio_url && podcast?.full_audio_url) || <div className="absolute h-full w-full bg-white opacity-60 top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>}
    </div>
  );
};

export default PodcastCard;
